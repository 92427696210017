import { clsx } from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { useScheduleStore } from '../../../schedule-store';
import useDayClasses from '../../useDayClasses';
import MonthDayClass from '../dayClass/MonthDayClass';
import './monthDay.scss';
import { useResize } from '../../useResize';
import { FC, MouseEvent } from 'react';

interface IMonthDay {
  readonly day: Dayjs;
  readonly currentStartRange: Dayjs;
}

const MonthDay: FC<IMonthDay> = ({ day, currentStartRange }) => {
  const { classes, setCurrentStartRange, setScheduleView } = useScheduleStore(
    ({ scheduleView, classes, setCurrentStartRange, requestParams, setScheduleView }) => ({
      scheduleView,
      setScheduleView,
      setCurrentStartRange,
      requestParams,
      classes,
    })
  );
  const { isMobileScreen } = useResize();
  const toSelectedDay =
    (hasClasses: boolean) =>
    ({ currentTarget }: MouseEvent<HTMLDivElement>) => {
      if (!hasClasses) {
        return;
      }
      const { id } = currentTarget;
      setCurrentStartRange(dayjs(id));
      const element = document.getElementById('small-class-container');
      if (isMobileScreen && element) element.scrollIntoView({ block: 'end', behavior: 'smooth' });
      if (!isMobileScreen) setScheduleView('day');
    };
  const dayClasses = useDayClasses(classes, day);
  const hasClasses = dayClasses?.length > 0;
  const isFirstDayOfMonth = day.date() === 1;
  const isCurrentMonth = day.month() === currentStartRange.month();
  const isToday = day.isSame(dayjs(), 'day');
  const isSelected = day.isSame(currentStartRange);
  return (
    <div
      className={clsx(
        'month-day',
        hasClasses && 'has-classes',
        isToday && 'month-today',
        !isCurrentMonth && 'not-current-month',
        isFirstDayOfMonth && 'first-day-of-month',
        isMobileScreen && 'mobile',
        isSelected && 'selected'
      )}
      id={day.format('YYYY-MM-DD')}
      onClick={toSelectedDay(hasClasses)}
    >
      <div className="date">
        <span className={clsx({ today: isToday })}>{day.format('D')}</span>
      </div>
      {dayClasses?.length > 0 && (
        <div className={clsx('classes')}>
          {dayClasses?.map((classData, idx) => (
            <MonthDayClass classData={classData} idx={idx} classesCount={dayClasses?.length} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MonthDay;
