import { Avatar, Backdrop, Box, Button as ButtonMui, Container, Fade, Modal, Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { IoInformationCircle } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';

// Files
import { api } from '../../../constants/api';
import { hexToRGB, removeAuthStorage } from '../../../constants/constants';
import './ScheduleClass.scss';
import { classTime } from './index';

// Components
import ClosePopup from '../../../components/IconComponents/ClosePopup';

// Context
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { toast } from 'sonner';
import { AccountPopupContext, IsLoggedInContext } from '../../../components/App/App';
import Metro from '../../../components/IconComponents/Metro/Metro';
import RoleRadios from '../../../components/RoleRadios/ChooseRole';
import { useResize } from '../zScheduleCal/useResize';
import { Level } from 'shared/ui/level/level';
import { IClass } from 'shared/types/class';
import { ALL_LEVELS, LIGHT_LEVEL } from 'shared/constants/levels';
import { TimeIcon, TrainerIcon } from 'shared/icons';
import { getClassTime } from 'shared/lib/getClassTime';
import img from './image.png';
import clsx from 'clsx';
import { DayClassPreview } from '../zScheduleCal/Day/dayClassPreview/dayClassPreview';
import { WeekClassPreview } from '../zScheduleCal/Week/weekClassPreview/weekClassPreview';
import { Button } from 'shared/ui/button';
dayjs.locale('ru');

interface IScheduleClass {
  classData: IClass;
  isWeek: boolean;
}
const ScheduleClass: FC<IScheduleClass> = ({ classData, isWeek }) => {
  const navigate = useNavigate();

  // Context
  const { isLoggedIn, setIsLoggedIn } = useContext(IsLoggedInContext); // Use app user state context
  const { setIsPopupAccountOpen } = useContext(AccountPopupContext);

  // Data
  const {
    id,
    date,
    duration,
    free_remove_to_klass,
    level,
    limit,
    players,
    reserve,
    trainer,
    type,
    roles_available,
    is_role_mode,
    gym,
  } = classData;
  const classId = id; // Id занятия для запроса при выборе абонемента
  // State
  const [joined, setJoined] = useState(false); // Состояние записи пользователя
  const [isJoinClassPopupOpen, setIsJoinClassPopupOpen] = useState(false); // Join class popup state
  const [role, setRole] = useState<any>({}); //
  const [isChooseRolePopupOpen, setIsChooseRolePopupOpen] = useState(false);
  const [classAbonement, setClassAbonement] = useState([]); // Список абонементов в занятии
  const [joinedReserve, setJoinedReserve] = useState(false); // Состояние записи пользователя в резерв
  const [playersCount, setPlayersCount] = useState(players.length); // Количество игроков занятия
  const [reserveCount, setReserveCount] = useState(reserve.length); // Количество игроков в резерве
  const [joinClassError, setJoinClassError] = useState(''); // Текст ошибки при записи на занятие
  const [joinClassErrorPopup, setJoinClassErrorPopup] = useState(false); // Попап с ошибкой при записи на занятие
  const [paymentData, setPaymentData] = useState<any>(null);

  const [freezeAbonement, setFreezeAbonement] = useState(null);
  const [joinedInfo, setJoinedInfo] = useState(false);
  const [delayedJoin, setDelayedJoin] = useState(null);

  useEffect(() => {
    const delayed = localStorage.getItem('delayed_appointment');
    const delayed_appointment = delayed ? JSON.parse(delayed) : null;
    if (delayed_appointment && delayed_appointment.classId === classId) {
      const schedule = document.querySelector('.schedule');
      schedule?.scrollIntoView({ behavior: 'smooth' });
      const delayed_join = delayed_appointment.few_abonements;
      handleJoinClass(id, delayed_appointment.role, delayed_join);
      localStorage.removeItem('delayed_appointment');
    }
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('user');
    const userId = user ? JSON.parse(user).id : null;
    if (players.includes(userId)) {
      setJoined(true);
    } else {
      setJoined(false);
    }
  }, [players, isLoggedIn]);

  /*** HTML class variables ***/ // const isJoinedReserveClass = isLoggedIn && joinedReserve ? 'joined' : '';
  // const isEndedClass = (isLoggedIn && ended) || isEndedWeek < 0 ? 'ended' : '';
  const freeRemoveDeadline = dayjs(date).subtract(free_remove_to_klass, 'hour');
  const isEndedClass = dayjs(date).diff(dayjs()) < 0;
  async function getPaymentData() {
    try {
      const {
        data: { has_trial_training },
      } = await api.get('has-trial-training/');
      const { data: classAbonements } = await api.get(`product/get_products_for_klass/${classId}`);
      const trialAbonement = classAbonements.find(({ title }: any) => title === 'Пробная тренировка');
      if (!has_trial_training && trialAbonement) {
        const { id: trialAbonementId } = trialAbonement;
        const classAbonementsWithoutTrial = classAbonements.filter(({ id }: any) => id !== trialAbonementId);
        return await classAbonementsWithoutTrial;
      }
      return await classAbonements;
    } catch (e) {
      toast.error('Произошла ошибка, попробуйте снова');
    }
  }
  // check count players
  const crowded = playersCount >= limit;
  const fillPercentage = `${(playersCount * 100) / limit}%`;
  /*** Handlers ***/
  // Join class
  function handleJoinClass(classId: any, choosenRole: any = {}, delayed_join = false) {
    if (!isLoggedIn) {
      setJoined(false);
      setIsLoggedIn(false); // Logout user
      setIsPopupAccountOpen(true); // Open login popup
      return;
    }
    // Прверка токена и запрос
    if (!localStorage.getItem('access_token')) {
      setIsPopupAccountOpen(true);
    } else {
      if (is_role_mode && !choosenRole.role) {
        setIsChooseRolePopupOpen(true);
        return;
      }
      setRole(choosenRole);
      api
        .post(`make_as_training/${classId}/`, choosenRole)
        .then(({ data }) => {
          // Выбор абонемента
          if (data.length === 1 || delayed_join) {
            const firstAbonement = data[0];
            const lastAbonement = data[data.length - 1];
            const abonementId = delayed_join ? lastAbonement.id : firstAbonement.id;
            api
              .post(`make_as_training/${classId}/${abonementId}/`, choosenRole)
              .then(({ data }) => {
                setIsChooseRolePopupOpen(false);
                setPlayersCount(data.players_count);
                setJoined(true);
                setJoinedInfo(true);
                setPaymentData(null);
                setFreezeAbonement(null);
                setJoinClassErrorPopup(true);
              })
              .catch(async ({ response }) => {
                const msg = response.data.message;
                switch (msg) {
                  case 'Абонемент заморожен':
                    setFreezeAbonement(data[0].id);
                    setJoinClassError('Ваш абонемент заморожен');
                    break;
                  case 'Ваш абонемент к этому времени будет недействительным.':
                    setJoinClassError(msg + ' Для записи на тренировку купите подходящий абонемент');
                    const classAbonements = await getPaymentData();
                    setPaymentData(classAbonements);
                    break;
                  default:
                    setJoinClassError(msg);
                }
                setJoinClassErrorPopup(true);
              });
          } else {
            setClassAbonement(data);
            setIsJoinClassPopupOpen(true);
          }
        })
        .catch(async ({ response }) => {
          // Logout user
          if (response.status === 401) {
            removeAuthStorage();

            setIsLoggedIn(false); // Logout user
            setIsPopupAccountOpen(true); // Open login popup
          }

          if (response.status === 403) {
            if (response.data.message === 'Для записи на тренировку купите подходящий абонемент.') {
              const classAbonements = await getPaymentData();
              setPaymentData(classAbonements);
            } else {
              setPaymentData(null);
            }

            // Ошибки, когда юзер записывается в неподходящую группу
            setJoinClassError(response.data.message);
            setJoinClassErrorPopup(true);
            // setTimeout(() => setJoinClassError(''), 3000);
          }
        });
    }
  }

  // Choose abonement if it's more than one
  const handleChooseAbonement = (classId: any, abonementId: any) => {
    api
      .post(`make_as_training/${classId}/${abonementId}/`, role)
      .then(({ data }) => {
        setPlayersCount(data.players_count);
        setJoined(true);
        setJoinedInfo(true);
        setIsChooseRolePopupOpen(false);
        setPaymentData(null);
        setJoinClassErrorPopup(true);
        setFreezeAbonement(null);
      })
      .catch(async ({ response }) => {
        const msg = response.data.message;
        switch (msg) {
          case 'Абонемент заморожен':
            setFreezeAbonement(abonementId);
            setJoinClassError('Этот абонемент заморожен');
            break;
          case 'Ваш абонемент к этому времени будет недействительным.':
            setJoinClassError(msg + ' Для записи на тренировку купите подходящий абонемент');
            const classAbonements = await getPaymentData();
            setPaymentData(classAbonements);
            break;
          default:
            setJoinClassError(msg);
        }
        setJoinClassErrorPopup(true);
      });
  };

  const handleUnfreezeAbonement = (abonementId: any) => {
    api
      .post(`freeze_product_off/${abonementId}/`)
      .then(() => {
        toast.success('Абонемент успешно разморожен');
        setJoinClassErrorPopup(false);
        setFreezeAbonement(null);
      })
      .catch(() => {
        toast.error('Произошла ошибка, попробуйте снова');
      });
  };

  // Leave class
  const handleLeaveClass = (classId: any) => {
    if (!isLoggedIn) {
      setJoined(false);
      setIsLoggedIn(false); // Logout user
      setIsPopupAccountOpen(true); // Open login popup
      return;
    }

    api
      .post(`remove_as_training/${classId}/`, {})
      .then(({ data }) => {
        // попап для уведомления, что бесплатная отмена закончилась
        if (!data.free && !joinedReserve) {
          setJoinClassErrorPopup(true);
          setJoinClassError(data.message);
        }
        if (joinedReserve) {
          setReserveCount(reserveCount - 1);
          setJoinedReserve(false);
        }
        setPlayersCount(data.players_count);
        setJoined(false);
      })
      .catch(({ response }) => {
        if (response.data.message === 'Тренировка уже закончилась.') {
          toast.error('Запись на тренировку уже закончилась');
        }
        // Logout user
        if (response.status === 401) {
          removeAuthStorage();

          setJoined(false); // показать кнопку записи на занятие, когда юзера разлогинивает
          setIsLoggedIn(false); // Logout user
          setIsPopupAccountOpen(true); // Open login popup
        }
      });
  };

  // Close join error popup
  const handleErrorPopup = () => {
    setJoinClassErrorPopup(false);
    setFreezeAbonement(null);
    setPaymentData(null);
    setJoinedInfo(false);
    setJoinClassError('');
  };

  // Redirect to the payment page
  const handleRedirectToPayment = (id: any) => {
    api
      .get(`pay/${id}`)
      .then(({ data }) => {
        const delayed_data = {
          classId,
          role,
          few_abonements: classAbonement.length > 0 ? true : false,
        };
        localStorage.setItem('delayed_appointment', JSON.stringify(delayed_data));
        window.location = data.url;
        setJoinClassErrorPopup(false);
      })
      .catch(({ response }) => {
        if (response.data.detail) {
          toast.error(response.data.detail);
        } else {
          toast.error('Произошла ошибка, попробуйте снова');
        }
      });
  };
  const { isMobileScreen, X_LARGE_W } = useResize();
  const currentLevel = ALL_LEVELS.find((lvl) => lvl.title === level.title);
  return (
    <>
      <div
        className={clsx('schedule-class', isWeek ? 'week-view-class' : 'day-view-class', isEndedClass && 'ended-class')}
      >
        {isWeek ? (
          <WeekClassPreview classData={classData} level={currentLevel} />
        ) : (
          <DayClassPreview
            classData={classData}
            playersCount={playersCount}
            freeRemoveDeadline={freeRemoveDeadline}
            level={currentLevel}
          />
        )}
        {!joined && <Button onClick={() => handleJoinClass(classId)}>Записаться</Button>}

        {isLoggedIn && joined && (
          <Button onClick={() => handleLeaveClass(classId)} className="btn-leave">
            Отменить запись
          </Button>
        )}
        {isEndedClass && <div className="ended">Тренировка завершена</div>}
      </div>
      {/*** Abonement popup ***/}
      <Modal
        aria-labelledby="transition-modal-join-title"
        aria-describedby="transition-modal-join-description"
        classes={{ root: 'popup-alert-wrapper' }}
        open={isJoinClassPopupOpen}
        onClose={() => setIsJoinClassPopupOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={isJoinClassPopupOpen}>
          <Stack spacing={2} justifyContent={'center'} alignItems={'center'} className="popup-alert">
            <Typography
              id="transition-modal-join-title"
              variant="h4"
              component={'p'}
              sx={{ fontFamily: 'inherit', marginBottom: '40px' }}
            >
              Выберите абонемент
            </Typography>

            {classAbonement.length > 0 &&
              classAbonement.map(({ id, product }) => {
                return (
                  <button
                    key={id}
                    className="abonement-list__item btn btn--bg btn--octagon"
                    onClick={() => {
                      // handleChooseAbonement(classId, id, setJoined);
                      handleChooseAbonement(classId, id);

                      setIsJoinClassPopupOpen(false);
                    }}
                  >
                    {product}
                  </button>
                );
              })}
            <button className="popup__close" onClick={() => setIsJoinClassPopupOpen(false)}>
              <ClosePopup />
            </button>
          </Stack>
        </Fade>
      </Modal>
      {/*** Join/leave class error popup ***/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        classes={{ root: 'popup-alert-wrapper' }}
        open={joinClassErrorPopup}
        onClose={handleErrorPopup}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={joinClassErrorPopup}>
          <Box className="popup-alert">
            <Typography id="transition-modal-title" variant="h6" component="p" sx={{ fontFamily: 'inherit' }}>
              {joinClassError}
            </Typography>

            {paymentData && (
              <>
                {paymentData.map(({ id, title }: any) => (
                  <button key={id} className="btn btn--bg btn--octagon" onClick={() => handleRedirectToPayment(id)}>
                    {title}
                  </button>
                ))}
                <ButtonMui
                  variant="text"
                  sx={{
                    margin: '30px auto 0',
                    color: 'white',
                    width: '100%',
                    height: '50px',
                    maxWidth: '250px',
                    fontFamily: '"Docker", "Helvetica", "Arial", sans-serif',
                    fontSize: '20px',
                  }}
                  startIcon={<IoInformationCircle fontSize={30} style={{ marginBottom: '4px' }} />}
                >
                  <Link to={'/payment'} target="_blank">
                    Подробнее
                  </Link>
                </ButtonMui>
              </>
            )}
            {freezeAbonement && (
              <button className="btn btn--bg btn--octagon" onClick={() => handleUnfreezeAbonement(freezeAbonement)}>
                Разморозить
              </button>
            )}
            {joinedInfo && (
              <Stack fontFamily={'inherit'} spacing={3}>
                <Typography variant="h6" component={'p'}>
                  {`Вы записаны на тренировку ${definitionWeekDay(dayjs(date).format('dddd'))}, ${dayjs(date).format(
                    'D MMMM'
                  )}!`}
                </Typography>
                {role.role && (
                  <Stack component={'p'}>
                    <Typography variant="h6" component={'p'}>
                      Амплуа
                    </Typography>
                    <Typography variant="h6" component={'p'}>
                      {role.role}
                    </Typography>
                  </Stack>
                )}
                <Stack component={'p'} fontWeight={'bold'}>
                  <Typography variant="h6" component={'p'}>{`Зал`}</Typography>
                  <Typography variant="h6" component={'p'}>
                    {gym.address}
                  </Typography>
                </Stack>
                <Stack component={'p'}>
                  <Typography variant="h6" component={'p'}>
                    {'Время'}
                  </Typography>
                  <Typography variant="h6" component={'p'}>{`c ${dayjs(date).format('HH:mm')} до ${dayjs(date)
                    .add(duration, 'm')
                    .format('HH:mm')}`}</Typography>
                </Stack>
                <Stack component={'p'}>
                  <Typography variant="h6" component={'p'}>
                    {'Тренер'}
                  </Typography>
                  <Typography variant="h6" component={'p'}>{`${trainer.first_name} ${trainer.last_name}`}</Typography>
                </Stack>
              </Stack>
            )}

            <button className="popup__close" onClick={handleErrorPopup}>
              <ClosePopup />
            </button>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        classes={{ root: 'popup-alert-wrapper' }}
        open={isChooseRolePopupOpen}
        onClose={() => setIsChooseRolePopupOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={isChooseRolePopupOpen}>
          <Box className="popup-alert">
            <h2 id="transition-modal-title">Выберите амплуа</h2>

            <RoleRadios roles={roles_available} handleJoinClass={(role: any) => handleJoinClass(classId, role)} />
            <button className="popup__close" onClick={() => setIsChooseRolePopupOpen(false)}>
              <ClosePopup />
            </button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

function definitionWeekDay(weekday: any) {
  const preposition = weekday === 'вторник' ? 'во' : 'в';

  return `${preposition} ${weekday.replace('а', 'у').toUpperCase()}`;
}

export default ScheduleClass;
