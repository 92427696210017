import { Box, Stack } from '@mui/material';
import React, { FC } from 'react';

export interface ILevel {
  readonly colors: string[];
  readonly width: number;
  readonly fontSize?: number;
  readonly children?: React.ReactNode;
  readonly gap?: number;
}

export const Level: FC<ILevel> = ({ colors, width }) => {
  return (
    <Stack direction={'row'} gap={0.3} justifyContent="center" alignItems={'baseline'}>
      {colors.map((color, idx) => (
        <Box width={`${width}px`} height={`${width * (idx + 1 * 3)}px`} bgcolor={color}></Box>
      ))}
    </Stack>
  );
};
