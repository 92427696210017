import { useQuery } from '@tanstack/react-query';
import getClasses from './getClasses';
import { useSearchParams } from 'react-router-dom';
import { useScheduleStore } from './schedule-store';

export default function useClassesQuery(params: string) {
  return useQuery({
    queryKey: [params],
    queryFn: () => getClasses(params),
    staleTime: 60000 * 5,
  });
}
