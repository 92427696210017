import { Box, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC } from 'react';
import './week.scss';
import clsx from 'clsx';
import useRange from '../useRange';
import { useScheduleStore } from 'pages/SchedulePage/schedule-store';

interface IWeekDays {
  daysRange: string[];
}
const WeekDays: FC<IWeekDays> = ({ daysRange }) => {
  const isToday = (day: string) => dayjs().day() === dayjs(day).day();
  const { currentStartRange } = useRange();
  const isSelected = (day: string) => currentStartRange.day() === dayjs(day).day();
  const { setCurrentStartRange, scheduleView, setScheduleView } = useScheduleStore(
    ({ setCurrentStartRange, scheduleView, setScheduleView }) => ({
      setCurrentStartRange,
      scheduleView,
      setScheduleView,
    })
  );

  return (
    <div className="week-days">
      {daysRange.map((day, i) => (
        <div
          key={day}
          onClick={() => {
            if (scheduleView === 'week') {
              setScheduleView('day');
            }
            setCurrentStartRange(dayjs(day));
          }}
          className={clsx('date', isToday(day) && 'today', isSelected(day) && scheduleView === 'day' && 'selected')}
        >
          <span>{dayjs(day).format('dd')} </span>
          <span>{dayjs(day).format('DD.MM')}</span>
        </div>
      ))}
    </div>
  );
};

export default WeekDays;
