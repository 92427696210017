import { FC } from 'react';
import { LIGHT_LEVEL } from 'shared/constants/levels';
import { IClass } from 'shared/types/class';
import { GymPhoto } from 'shared/ui';
import { Interval } from 'shared/ui/interval';
import { Level } from 'shared/ui/level';
import img from './image.png';
import { ClassGym } from 'shared/ui/classGym';
import { Trainer } from 'shared/ui/trainer';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import './dayClassPreview.scss';

interface IDayClassPreview {
  readonly classData: IClass;
  readonly freeRemoveDeadline: Dayjs;
  readonly playersCount: number;
  readonly level: typeof LIGHT_LEVEL | undefined;
}

export const DayClassPreview: FC<IDayClassPreview> = ({
  classData,
  level = LIGHT_LEVEL,
  freeRemoveDeadline,
  playersCount,
}) => {
  const { duration, date, gym, trainer, limit } = classData;
  return (
    <div className="day_mode">
      <div className="class-title">
        <div className="class-level">
          <Level colors={level.colors} width={3} />
          <span>{level.title}</span>
        </div>
        <Interval start={date} duration={duration} />
      </div>
      <GymPhoto img={img} />
      <ClassGym title={gym.title} />
      <span>{gym.address}</span>
      <Trainer first_name={trainer.first_name} last_name={trainer.last_name} />
      <div className="class-info">
        <div className="fill">
          {new Array(limit).fill(null).map((_, idx) => (
            <div key={idx} className={clsx('fill-item', idx + 1 <= playersCount && 'filled')} />
          ))}
          <span>{`${playersCount} / ${limit}`}</span>
        </div>
        <span>Бесплатная отмена до {freeRemoveDeadline.format('HH:mm, D MMM')}</span>
      </div>
    </div>
  );
};
