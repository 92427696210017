import clsx from 'clsx';
import Metro from 'components/IconComponents/Metro/Metro';
import { FC } from 'react';
import './classGym.scss';

interface IClassGym {
  title: string;
  className?: string;
}

export const ClassGym: FC<IClassGym> = ({ title, className }) => {
  return (
    <div className={clsx('class-gym', className)}>
      <Metro />
      <span>{title}</span>
    </div>
  );
};
