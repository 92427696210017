import { FC } from 'react';
import './weekClassPreview.scss';
import { IClass } from 'shared/types/class';
import { LIGHT_LEVEL } from 'shared/constants/levels';
import { Level } from 'shared/ui/level';
import { GymPhoto } from 'shared/ui';
import img from './image.png';
import { ClassGym } from 'shared/ui/classGym';
import { Interval } from 'shared/ui/interval';
import { Trainer } from 'shared/ui/trainer';
import { useResize } from '../../useResize';

interface IWeekClassPreview {
  readonly classData: IClass;
  readonly level: typeof LIGHT_LEVEL | undefined;
}

export const WeekClassPreview: FC<IWeekClassPreview> = ({ classData, level = LIGHT_LEVEL }) => {
  const { gym, date, duration, trainer } = classData;
  const { X_LARGE_W } = useResize();
  return (
    <div className="week_mode">
      <div className="class-level">
        <Level colors={level.colors} width={3} />
        <span>{level.title}</span>
        {X_LARGE_W && 'уровень'}
      </div>
      <GymPhoto img={img} />
      <ClassGym title={gym.title} />
      <Interval start={date} duration={duration} />
      <Trainer first_name={trainer.first_name} last_name={trainer.last_name} className="week_mode_trainer" />
    </div>
  );
};
