import { useEffect, useState } from 'react';

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobileScreen = width <= 768;
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const TABLET_W = width <= 1024 && width > 768;
  const MAX_TABLET_W = width <= 1024;
  const LARGE_W = width >= 1024 && width < 1440;
  const MIN_LARGE_W = width >= 1024;
  const MIN_TABLET_W = width >= 768;
  const X_LARGE_W = width >= 1440;
  return { width, isMobileScreen, LARGE_W, X_LARGE_W, TABLET_W, MIN_LARGE_W, MIN_TABLET_W, MAX_TABLET_W };
};
