import { FC } from 'react';

interface IDropDownIcon {
  className?: string;
}
const DropDownIcon: FC<IDropDownIcon> = ({ className }) => {
  return (
    <svg className={className} width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 6L12.1292 0L0.870835 0L6.5 6Z" fill="inherit" />
    </svg>
  );
};

export default DropDownIcon;
