import React from 'react';

// Files
import metroIcon from './metro-icon.png';
import { Icon } from '@mui/material';

const Metro = (props) => {
  return (
    <Icon {...props}>
      <img src={metroIcon} alt="metro-icon" title="metro-icon" />
    </Icon>
  );
};

export default Metro;
