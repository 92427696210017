import { Box } from '@mui/material';
import { useScheduleStore } from '../../schedule-store';
import CurrentRangeSwitcher from '../rangeSwitcher/RangeSwitcher';
import SwitchView from '../switchVIew/SwitchView';
import useRange from '../useRange';
import Sticky from 'react-stickynode';
import { useResize } from '../useResize';
import './scheduleHeader.scss';

export default function ScheduleHeader() {
  const { scheduleView, setCurrentStartRange, setRequestParams } = useScheduleStore(
    ({ scheduleView, currentStartRange, setCurrentStartRange, setRequestParams }) => ({
      scheduleView,
      setCurrentStartRange,
      setRequestParams,
    })
  );
  const { isMobileScreen } = useResize();
  const { ranges } = useRange();
  const { rangeString } = ranges[scheduleView];
  return (
    <Sticky top={'#header'} innerZ={2} className="schedule-header">
      <div className={'bar'}>
        <div className={'manage'}>
          <span>{rangeString}</span>
          {!isMobileScreen && <CurrentRangeSwitcher />}
        </div>
        {/* <Box className="schedule__filter" font={'5px'}>
        {isMobileScreen ? <ScheduleFilterMob /> : <ScheduleFilter />}
      </Box> */}
        <SwitchView />
      </div>
      {isMobileScreen && <CurrentRangeSwitcher />}
    </Sticky>
  );
}
