import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Stack } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useScheduleStore } from '../../schedule-store';
import useRange from '../useRange';
import { RiArrowLeftWideFill } from 'react-icons/ri';
import './rangeSwitcher.scss';
import { useResize } from '../useResize';
import ArrowLeft from 'components/IconComponents/ArrowLeft';
import ArrowRight from 'components/IconComponents/ArrowRight';

export default function CurrentRangeSwitcher() {
  const { scheduleView, setCurrentStartRange } = useScheduleStore(
    ({ scheduleView, currentStartRange, setCurrentStartRange, setRequestParams }) => ({
      scheduleView,
      setCurrentStartRange,
      setRequestParams,
    })
  );
  const { currentStartRange, ranges } = useRange();
  const { type, range } = ranges[scheduleView];

  const prevRange = currentStartRange.subtract(range, type);
  const nextRange = currentStartRange.add(range, type);
  const handleDownRange = () => {
    setCurrentStartRange(prevRange);
  };

  const handleUpRange = () => {
    setCurrentStartRange(nextRange);
  };

  return (
    <div className={'switcher'}>
      <button onClick={handleDownRange}>
        <ArrowLeft />
      </button>
      <button className="today-btn" onClick={() => setCurrentStartRange(dayjs())}>
        Сегодня
      </button>
      <button onClick={handleUpRange}>
        <ArrowRight />
      </button>
    </div>
  );
}
