import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
export function getMonth(date = dayjs()) {
  const year = dayjs().year();
  const month = date.month();

  const firstDayOfTheMonth = dayjs(new Date(year, month, 0)).day();
  let currentDayCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(0).map(() => {
      currentDayCount++;
      return dayjs(new Date(year, month, currentDayCount));
    });
  });
  return daysMatrix;
}

export function getRange(range, date = dayjs()) {
  let rangeStart = dayjs(date);
  const daysOfTheWeek = 7;
  if (range === daysOfTheWeek) {
    const firstDayOfTheWeek = 1;
    rangeStart = date.isoWeekday(firstDayOfTheWeek);
  }
  return new Array(range).fill(0).map((_, i) => {
    const currentDate = rangeStart.add(i, 'day');
    return currentDate;
  });
}
