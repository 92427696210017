import React, { createContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Files
import { api } from '../../constants/api';
import './App.scss';

// Components
import { Toaster } from 'sonner';
import RoutesList from '../../routes/RoutesList';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import AppPageBottomDecor from '../IconComponents/AppPageBottomDecor/AppPageBottomDecor';
import AppPageTopDecor from '../IconComponents/AppPageTopDecor/AppPageTopDecor';

// Context
export const IsLoggedInContext = createContext(); // User logged state context
export const MenuAndIconsContext = createContext({}); // Menu and icons context
export const AccountPopupContext = createContext(); // Account popup context
export const AddCommentPopupContext = createContext({}); // Add comment popup state context

function App() {
  const [menu, setMenu] = useState([]);
  const [icon, setIcon] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Logged user state for app
  const [isPopupAccountOpen, setIsPopupAccountOpen] = useState(false); // Account popup state
  const [isPopupLogoutOpen, setIsPopupLogoutOpen] = useState(false); // Account logout popup state

  const [addCommentPopupOpen, setAddCommentPopupOpen] = useState(false); // Add comment popup state
  const [isCommentPopupForm, setIsCommentPopupForm] = useState(false); // Comment popup form state
  const queryClient = new QueryClient();

  // Главная страница
  useEffect(() => {
    const userAccessToken = localStorage.getItem('access_token');

    setIsLoggedIn(!!userAccessToken);

    // Для меню
    api.get('menu/').then(({ data }) => setMenu(data));

    // Для иконок соц.сетей
    api.get('social/').then(({ data }) => setIcon(data));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <IsLoggedInContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
        <MenuAndIconsContext.Provider value={{ menu, icon }}>
          <AccountPopupContext.Provider
            value={{ isPopupAccountOpen, setIsPopupAccountOpen, isPopupLogoutOpen, setIsPopupLogoutOpen }}
          >
            <AddCommentPopupContext.Provider
              value={{ addCommentPopupOpen, setAddCommentPopupOpen, isCommentPopupForm, setIsCommentPopupForm }}
            >
              <ReactQueryDevtools />
              {/* <AppPageTopDecor /> */}

              <Header />

              <main>
                <RoutesList />
              </main>

              <Footer />
              <Toaster richColors position="bottom-center" />
              <AppPageBottomDecor />
            </AddCommentPopupContext.Provider>
          </AccountPopupContext.Provider>
        </MenuAndIconsContext.Provider>
      </IsLoggedInContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
