import clsx from 'clsx';
import { FC } from 'react';
import './gymPhoto.scss';

interface IGymPhoto {
  img: string;
  className?: string;
}

export const GymPhoto: FC<IGymPhoto> = ({ img, className }) => {
  return <img alt="gym" src={img} className={clsx('gym-photo', className)} />;
};
