import { FC } from 'react';
import './interval.scss';
import { getClassTime } from 'shared/lib/getClassTime';
import clsx from 'clsx';
import { TimeIcon } from 'shared/icons';

interface IInterval {
  readonly start: string;
  readonly duration: number;
  readonly withIcon?: boolean;
  readonly className?: string;
}

export const Interval: FC<IInterval> = ({ start, duration, className, withIcon = true }) => {
  const interval = getClassTime(start, duration);
  return (
    <div className={clsx('interval', className)}>
      {withIcon && <TimeIcon />}
      <span>{interval}</span>
    </div>
  );
};
