import { Box, Button, Menu, MenuItem } from '@mui/material';
import { clsx } from 'clsx';
import DropDownIcon from 'components/IconComponents/DropDownIcon';
import { MouseEvent, useState } from 'react';
import { IoIosToday } from 'react-icons/io';
import { TbCalendarMonth, TbCalendarWeek } from 'react-icons/tb';
import { useScheduleStore } from '../../schedule-store';
import { useResize } from '../useResize';
import './switchView.scss';
import { IconType } from 'react-icons';

const views: { type: 'day' | 'week' | 'month'; name: string; icon: IconType }[] = [
  { type: 'day', name: 'День', icon: IoIosToday },
  { type: 'week', name: 'Неделя', icon: TbCalendarWeek },
  { type: 'month', name: 'Месяц', icon: TbCalendarMonth },
];
export default function SwitchView() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { MAX_TABLET_W } = useResize();
  const { scheduleView, setScheduleView, setCurrentStartRange } = useScheduleStore(
    ({ setScheduleView, scheduleView, setCurrentStartRange }) => ({
      setScheduleView,
      scheduleView,
      setCurrentStartRange,
    })
  );
  const activeMode = views.find((view) => view.type === scheduleView);
  const ActiveIcon = activeMode?.icon ?? TbCalendarMonth;
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {MAX_TABLET_W ? (
        <>
          <button
            className="trigger-btn"
            onClick={handleOpen}
            id="format-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            aria-controls={open ? 'format-menu' : undefined}
          >
            <ActiveIcon />
            <span>{activeMode?.name}</span>
            <DropDownIcon className="dropdown-arrow" />
          </button>
          <Menu
            className="menu-list"
            anchorEl={anchorEl}
            id="format-menu"
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'format-button',
              sx: { bgcolor: '#292a2d', color: 'white', p: 0 },
            }}
          >
            <MenuItem
              className={clsx(scheduleView === 'day' && 'active')}
              onClick={() => {
                setAnchorEl(null);
                setScheduleView('day');
              }}
            >
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap="10px">
                <Box component={'span'}>
                  <IoIosToday />
                </Box>
                <Box component={'span'}>День</Box>
              </Box>
            </MenuItem>
            <MenuItem
              className={clsx(scheduleView === 'week' && 'active')}
              onClick={() => {
                setAnchorEl(null);
                setScheduleView('week');
              }}
            >
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap="10px">
                <Box component={'span'}>
                  <TbCalendarWeek />
                </Box>
                <Box component={'span'}>{'Неделя'}</Box>
              </Box>
            </MenuItem>
            <MenuItem
              className={clsx(scheduleView === 'month' && 'active')}
              onClick={() => {
                setAnchorEl(null);
                setScheduleView('month');
              }}
            >
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap="10px">
                <Box component={'span'}>
                  <TbCalendarMonth />
                </Box>
                <Box component={'span'}>Месяц</Box>
              </Box>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <div className="switch-view">
          {views.map((view) => {
            const Icon = view.icon;
            return (
              <button
                onClick={() => setScheduleView(view.type)}
                className={clsx(scheduleView === view.type && 'active')}
              >
                <Icon />
                {view.name}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
}
