import { Box } from '@mui/material';
import React from 'react';
import Day from './Day/Day';
import { getRange } from './utils';
import useRange from './useRange';
import { useResize } from './useResize';
import WeekDays from './Week/WeekDays';
import WeekDay from './Week/weekDay/WeekDay';

function ThreeDay({}) {
  const { currentStartRange, ranges } = useRange();
  const { range } = ranges['3-day'];
  const daysRange = getRange(range, currentStartRange);
  return (
    <>
      <WeekDays daysRange={daysRange} range={range} />
      <Box
        display={'grid'}
        gridTemplateColumns={`repeat(${range}, minmax(40px, 1fr))`}
        width={'100%'}
        // alignItems={'center'}
        justifyContent={'center'}
      >
        {daysRange.map((day) => (
          <WeekDay key={day.format('YYYY-MM-DD')} day={day} range={range} />
        ))}
      </Box>
    </>
  );
}

export default ThreeDay;

function ThreeDayWeekDays() {}
