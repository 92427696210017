import { Box } from '@mui/material';
import React from 'react';
import Day from '../Day/Day';
import { getRange } from '../utils';
import useRange from '../useRange';
import { useResize } from '../useResize';
import WeekDays from './WeekDays';
import WeekDay from './weekDay/WeekDay';
import './week.scss';

function Week() {
  const { currentStartRange, ranges } = useRange();
  const { range } = ranges['week'];
  const daysRange = getRange(range, currentStartRange);
  const { MIN_LARGE_W, width } = useResize();
  return (
    <div>
      {width > 1250 && <WeekDays daysRange={daysRange} range={range} />}
      <div className={'week-view'}>
        {daysRange.map((day) =>
          width < 1250 ? (
            <div className="week-mobile-mode">
              <div className="weekday-mobile-item">
                <p>{day.format('dd')}</p>
                <p>{day.format('DD.MM')}</p>
              </div>
              <WeekDay key={day.format('YYYY-MM-DD')} day={day} range={range} />
            </div>
          ) : (
            <WeekDay key={day.format('YYYY-MM-DD')} day={day} range={range} />
          )
        )}
      </div>
    </div>
  );
}

export default Week;
