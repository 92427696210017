import React from 'react';
import useDayClasses from '../../useDayClasses';
import { useScheduleStore } from '../../../schedule-store';
import ScheduleClass from '../../../ScheduleClass/ScheduleClass';
import { Box, Stack } from '@mui/material';
import './weekDay.scss';
import { Level } from 'shared/ui/level/level';
import { LIGHT_LEVEL } from 'shared/constants/levels';
import img from './image.png';
import Metro from 'components/IconComponents/Metro/Metro';
import { useResize } from '../../useResize';

const WeekDay = ({ day, range }) => {
  const { scheduleView, setCurrentStartRange, setScheduleView, classes, requestParams } = useScheduleStore(
    ({ scheduleView, classes, setScheduleView, setCurrentStartRange, requestParams }) => ({
      scheduleView,
      setScheduleView,
      setCurrentStartRange,
      requestParams,
      classes,
    })
  );
  const dayClasses = useDayClasses(classes, day);
  const { MIN_LARGE_W, width } = useResize();

  return (
    <div className="week-class-card">
      {dayClasses?.length > 0
        ? dayClasses.map((classData) => <ScheduleClass classData={classData} isWeek />)
        : width < 1250 && <p>Нет тренировок</p>}
    </div>
  );
};

export default WeekDay;
