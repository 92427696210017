import { FC } from 'react';
import './button.scss';
import clsx from 'clsx';

interface IButton {
  /** Button text. */
  readonly children: string;
  /** Button disable modifier. */
  readonly disabled?: boolean;
  readonly theme?: 'green' | 'transparent-green';
  /** Additional styles. */
  readonly className?: string;
  /** The function is executed when the button is pressed. */
  readonly onClick?: () => void;
}

export const Button: FC<IButton> = (props) => {
  const { children, disabled = false, className, onClick, theme = 'transparent-green' } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx('button', `button_theme_${theme}`, disabled && 'button_disabled', className)}
    >
      {children}
    </button>
  );
};
