import { MouseEvent, useContext, useEffect, useState } from 'react';

// Files
import { api } from '../../constants/api';
import './SchedulePage.scss';

// Components
import Image from '../../components/Image/Image';
// import ScheduleClassReviews from './ScheduleClassReviews/ScheduleClassReviews';

// Context
import { Box } from '@mui/material';
import { IsLoggedInContext } from '../../components/App/App';
import { useScheduleStore } from './schedule-store';
import useClassesQuery from './useClasessQuery';
import Schedule from './zScheduleCal/Schedule';
import { useResize } from './zScheduleCal/useResize';
import useRange from './zScheduleCal/useRange';
import useSchedule from './zScheduleCal/useSchedule';
import ScheduleHeader from './zScheduleCal/scheduleHeader/ScheduleHeader';
import { ALL_LEVELS, USER_LEVELS } from 'shared/constants/levels';
import { Level } from 'shared/ui/level/level';
import dayjs from 'dayjs';
import useDayClasses from './zScheduleCal/useDayClasses';
import { Interval } from 'shared/ui/interval';
import titleImg from './image.png';
// export const MenuFilterContext = createContext({}); // контекст меню фильтра
// export const ClassContext = createContext({}); // контекст занятия
// export const ClassReviewsContext = createContext({}); // контекст отзывов занятия

const SchedulePage = () => {
  // Set height for schedule img

  // Use context
  const { isLoggedIn, setIsLoggedIn } = useContext(IsLoggedInContext); // Use app user state context

  // Page
  const [pageInfo, setPageInfo] = useState<{ title: string; image_mob: string; image: string }>(); // Page title and subtitle info state

  useEffect(() => {
    if (isLoggedIn) {
      api
        .get('get_my_id/')
        .then(({ data }) => localStorage.setItem('user', JSON.stringify(data)))
        .catch(({ response }) => {
          console.log(response);
        });
    }
  }, [isLoggedIn]);
  const { setClasses, scheduleView, requestParams, setRequestParams, setScheduleView, setCurrentStartRange } =
    useScheduleStore(
      ({
        classes,
        setClasses,
        requestParams,
        setRequestParams,
        setScheduleView,
        scheduleView,
        setCurrentStartRange,
      }) => ({
        classes,
        setClasses,
        requestParams,
        setRequestParams,
        setScheduleView,
        scheduleView,
        setCurrentStartRange,
      })
    );
  const { isMobileScreen, MIN_LARGE_W } = useResize();
  const { data: classes, isSuccess } = useClassesQuery(requestParams);
  useEffect(() => {
    if (isSuccess) {
      setClasses(classes);
    }
  }, [isLoggedIn, isSuccess, setClasses, classes]);
  // setClasses(classes);
  useEffect(() => {
    // Set page info
    api.get('schedule-page/').then(({ data }) => setPageInfo(data));

    if (isMobileScreen && scheduleView === 'week') {
      setScheduleView('3-day');
    }
  }, []);
  const { currentStartRange, ranges } = useRange();

  const ScheduleBody: any = useSchedule();
  return (
    <section className="schedule">
      {/*** Page heading ***/}
      <article>
        <h1 className="title">{'Расписание'}</h1>
        {MIN_LARGE_W && (
          <div className="schedule-ad-wrapper">
            <div className="schedule-ad-image">
              {/* <Image mob={pageInfo?.image_mob} desk={titleImg} /> */}
              <img src={titleImg} alt="title-schedule" />
            </div>
            <ul className="levels">
              {USER_LEVELS.map(({ colors, title }) => {
                return (
                  <li className="level" key={title}>
                    <Level colors={colors} width={6} />
                    <span>{`${title} уровень`}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </article>
      <article>
        <ScheduleHeader />
        <div className="classes">
          <ScheduleBody day={currentStartRange} currentStartRange={currentStartRange} />
        </div>
      </article>

      {/*** Class reviews popup ***/}
      {/* <ScheduleClassReviews /> */}
    </section>
  );
};

export default SchedulePage;
