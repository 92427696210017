import { Box } from '@mui/material';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import React from 'react';
import Day from '../Day/Day';
import { getMonth } from '../utils';
import { useScheduleStore } from '../../schedule-store';
import useRange from '../useRange';
import WeekDays from '../Week/WeekDays';
import MonthDay from './monthDay/MonthDay';
import { MonthWeekDays } from './monthWeekDays/monthWeekDays';
import './month.scss';
import { Interval } from 'shared/ui/interval';
import { useResize } from '../useResize';
import useDayClasses from '../useDayClasses';
dayjs.extend(isoWeek);
dayjs.locale('ru');

function Month({ currentStartRange }) {
  const { classes, setScheduleView } = useScheduleStore(({ classes, setScheduleView }) => ({
    classes,
    setScheduleView,
  }));
  const month = getMonth(currentStartRange);
  const { isMobileScreen } = useResize();
  const selectedDayClasses = useDayClasses(classes, currentStartRange) ?? [];
  return (
    <div>
      <MonthWeekDays />
      <div className="month-view">
        {month.map((row, i) => (
          <div className="month-week" key={i}>
            {row.map((day) => {
              return <MonthDay key={day.format('YYYY-MM-DD')} day={day} currentStartRange={currentStartRange} />;
            })}
          </div>
        ))}
      </div>
      {selectedDayClasses.length > 0 && isMobileScreen && (
        <div id="small-class-container" className="selected-day-classes">
          <div className="class-date">
            <span>{currentStartRange.format('dddd')}</span>
            <span>{currentStartRange.format('D.MM')}</span>
          </div>
          {selectedDayClasses.map(({ date, duration, id, level }) => (
            <div
              key={id + date}
              className="class"
              style={{ borderColor: level.color }}
              onClick={() => setScheduleView('day')}
            >
              <Interval start={date} duration={duration} />
              <span>К тренировке</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Month;
