export const LIGHT_LEVEL = {
  title: 'Начальный',
  colors: ['#72BF44', '#fff', '#fff'],
};

export const MEDIUM_LEVEL = {
  title: 'Средний',
  colors: ['#FAF62D', '#FAF62D', '#fff'],
};

export const HARD_LEVEL = {
  title: 'Сильный',
  colors: ['#D33838', '#D33838', '#D33838'],
};
export const TEAM_LEVEL = {
  title: 'Командный',
  colors: ['#2656FF', '#2656FF', '#2656FF'],
};

export const USER_LEVELS = [LIGHT_LEVEL, MEDIUM_LEVEL, HARD_LEVEL];

export const ALL_LEVELS = [LIGHT_LEVEL, MEDIUM_LEVEL, HARD_LEVEL, TEAM_LEVEL];
