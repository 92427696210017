import React from 'react';
import { useScheduleStore } from '../schedule-store';
import Day from './Day/Day';
import ThreeDay from './ThreeDay';
import Week from './Week/Week';
import Month from './Month/Month';

function useSchedule() {
  const view = useScheduleStore((state) => state.scheduleView);
  const components = {
    day: Day,
    '3-day': ThreeDay,
    week: Week,
    month: Month,
  };
  return components[view];
}

export default useSchedule;
