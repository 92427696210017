import dayjs from 'dayjs';
import { FC } from 'react';
import './monthWeekdays.scss';
interface IMonthWeekDays {}

export const MonthWeekDays: FC<IMonthWeekDays> = () => {
  const weekdays = new Array(7).fill(null);
  return (
    <div className="weekdays">
      {weekdays.map((_weekday, idx) => (
        <div className="day" key={idx}>
          <span>
            {dayjs()
              .day(idx + 1)
              .format('dd')}
          </span>
        </div>
      ))}
    </div>
  );
};
