import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { getMonth } from './zScheduleCal/utils';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import useRange, { getStartEndRange } from './zScheduleCal/useRange';
dayjs.locale('ru');

interface IScheduleStore {
  classes: any[];
  scheduleView: 'day' | 'week' | 'month' | '3-day';
  currentStartRange: dayjs.Dayjs;
  requestParams: string;
  setRequestParams: (requestParams: string) => void;
  setClasses: (classes: any[]) => void;
  setCurrentStartRange: (date: dayjs.Dayjs) => void;
  setScheduleView: (scheduleView: 'day' | 'week' | 'month' | '3-day') => void;
}

export const useScheduleStore = create<IScheduleStore>()(
  persist(
    (set, get) => {
      return {
        classes: [],
        scheduleView: 'week',
        currentStartRange: dayjs(),
        requestParams: getStartEndRange(),
        setRequestParams: (requestParams) => {
          set({ requestParams });
        },
        setClasses: (classes) => set({ classes }),
        setCurrentStartRange: (date) => {
          const requestParams = getStartEndRange(date, get().scheduleView);
          set({ requestParams });
          set({ currentStartRange: date });
        },
        setScheduleView: (scheduleView) => {
          const requestParams = getStartEndRange(get().currentStartRange, scheduleView);
          set({ requestParams });
          set({ scheduleView });
        },
      };
    },
    { name: 'Schedule', storage: createJSONStorage(() => sessionStorage) }
  )
);
