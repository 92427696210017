import dayjs from 'dayjs';
import { useScheduleStore } from '../schedule-store';
import { useResize } from './useResize';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

export default function useRange() {
  const { isMobileScreen } = useResize();
  const [currentStartRange, scheduleView] = useScheduleStore((state) => [
    dayjs(state.currentStartRange),
    state.scheduleView,
  ]);
  const type = isMobileScreen && scheduleView === 'week' ? '3-day' : scheduleView;
  const firstDayOfTheWeek = currentStartRange.isoWeekday(1);
  const dayString = currentStartRange.format('dddd D MMMM');
  const rangeString = currentStartRange.format('MMMM YYYY');
  const weekString = `${firstDayOfTheWeek.format('D')} - ${firstDayOfTheWeek.add(6, 'day').format('D MMMM')}`;
  const threeDayString = `${currentStartRange.format('D')} - ${currentStartRange.add(2, 'day').format('D MMMM')}`;
  const startEndRange = getStartEndRange(currentStartRange, type);

  return {
    currentStartRange,
    ranges: {
      month: { type: 'month', range: 1, rangeString },
      week: { type: 'day', range: 7, rangeString },
      '3-day': { type: 'day', range: 3, rangeString },
      day: { type: 'day', range: 1, rangeString: isMobileScreen ? dayString : rangeString },
    },
  };
}

export function getStartEndRange(currentDate: dayjs.Dayjs = dayjs(), type = 'week') {
  let start;
  let end;
  // const map = {
  //   start_date: null,
  //   end_date: null,
  //   toString: () => `start_date=${this.start_date}&end_date=${this.end_date}`,
  // };
  const date = dayjs(currentDate);
  const map: any = {};
  switch (type) {
    case 'month': {
      const startRange = date.startOf('month').isoWeekday(1);
      const endRange = startRange.add(6, 'week');
      start = startRange.format('YYYY-MM-DD');
      end = endRange.format('YYYY-MM-DD');
      break;
    }
    case 'week': {
      start = date.isoWeekday(1).format('YYYY-MM-DD');
      end = date.isoWeekday(1).add(7, 'day').format('YYYY-MM-DD');
      break;
    }
    case '3-day': {
      start = date.format('YYYY-MM-DD');
      end = date.add(3, 'day').format('YYYY-MM-DD');
      break;
    }
    default: {
      start = date.format('YYYY-MM-DD');
      end = date.add(1, 'day').format('YYYY-MM-DD');
      break;
    }
  }
  map['start_date'] = start;
  map['end_date'] = end;
  return `start_date=${start}&end_date=${end}`;
}
