import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import ScheduleClass from '../../ScheduleClass/ScheduleClass';
import { useScheduleStore } from '../../schedule-store';
import useDayClasses from '../useDayClasses';
import useRange from '../useRange';
import useClassesQuery from '../../useClasessQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './Day.scss';

import { EffectCoverflow, Pagination } from 'swiper/modules';
import { useResize } from '../useResize';
import WeekDays from '../Week/WeekDays';
import { getRange } from '../utils';

// init Swiper:

function Day({ day }) {
  const { scheduleView, setCurrentStartRange, setScheduleView, classes, requestParams } = useScheduleStore(
    ({ scheduleView, classes, setScheduleView, setCurrentStartRange, requestParams }) => ({
      scheduleView,
      setScheduleView,
      setCurrentStartRange,
      requestParams,

      classes,
    })
  );
  const dayClasses = useDayClasses(classes, day);
  const isEndedClass = (date) => dayjs(date).diff(dayjs()) < 0;
  const { isMobileScreen, width } = useResize();
  const { currentStartRange } = useRange();
  const range = getRange(7, currentStartRange);
  return (
    <>
      {!isMobileScreen && <WeekDays daysRange={range} />}
      {dayClasses?.length > 0 ? (
        // <Swiper
        //   grabCursor={true}
        //   effect="coverflow"
        //   spaceBetween={15}
        //   slidesPerView={'auto'}
        //   modules={[EffectCoverflow, Pagination]}
        //   className="mySwiper"
        //   // centeredSlides={dayClasses.length === 1}
        //   coverflowEffect={{
        //     rotate: 0,
        //     stretch: 0,
        //     depth: !isMobileScreen && dayClasses.length > 3 ? 100 : 0,
        //     modifier: 1,
        //     slideShadows: false,
        //   }}
        //   breakpoints={{
        //     768: {
        //       slidesPerView: '2',
        //       centeredSlides: dayClasses.length > 2,
        //       spaceBetween: 30,
        //     },
        //     1024: {
        //       slidesPerView: '3',
        //       spaceBetween: 40,
        //       centeredSlides: dayClasses.length > 3,
        //     },
        //   }}
        //   wrapperClass={
        //     (dayClasses.length === 1 && width > 650) || (dayClasses.length === 2 && width >= 1024) ? 'class-center' : ''
        //   }
        // >
        //   {dayClasses?.map((classData, idx) => (
        //     <SwiperSlide style={{ '--width': dayClasses.length > 1 ? '90%' : '100%' }}>
        //       <Stack
        //         key={classData.id}
        //         sx={{ position: 'relative' }}
        //         gap="8px"
        //         justifyContent={'center'}
        //         className="class"
        //         minWidth={'270px'}
        //         border={`2px solid #dcd7d7`}
        //         p={'10px'}
        //         borderRadius={'10px'}
        //       >
        //         <ScheduleClass
        //           classData={classData}
        //           idx={idx}
        //           classesCount={dayClasses?.length}
        //           scheduleView={scheduleView}
        //           isDay={scheduleView === 'day'}
        //         />
        //         {isEndedClass(classData.date) && (
        //           <Box className="ended_class" fontSize={'20px'}>
        //             <Typography variant="body1" component={'p'}>
        //               Тренировка завершена
        //             </Typography>
        //           </Box>
        //         )}
        //       </Stack>
        //     </SwiperSlide>
        //   ))}
        // </Swiper>

        <div className="day-preview">
          {dayClasses?.map((classData, idx) => (
            <ScheduleClass
              classData={classData}
              idx={idx}
              classesCount={dayClasses?.length}
              scheduleView={scheduleView}
              isDay={scheduleView === 'day'}
            />
          ))}
        </div>
      ) : (
        <Box display={'flex'} justifyContent={'center'} width={'100%'} minHeight={'667px'} alignItems={'center'}>
          Занятий нет
        </Box>
      )}
    </>
  );
}

export default Day;
